<template>
	<b-modal id="export-import-modal" hide-footer  hide-header-close no-close-on-backdrop size="md" >

		<template #modal-header> 
      
			<div>
				<v-icon name="chevron-left" @click="close()" />
			</div>
			<h5>
				EXPORT/IMPORT {{ assetInfo.symbol }}
			</h5> 
			<div></div>

    </template>


		<div  > 

			<div class="text-center"> <span class="bold"> BALANCE: </span> {{ formatAmount(init.fund.balance) }}  </div>
	     			<div class="alert alert-danger" v-if="showError"> {{ systemMsg }} </div>
	     			
			<div class="btn-group email-or-mobile">
            <button type="button" class="btn " v-bind:class="{ active: ei.state === 'export' }" v-on:click="toggleOption('export')">Export</button>
            <button type="button" class="btn " v-bind:class="{ active: ei.state === 'import' }" v-on:click="toggleOption('import')">Import</button> 
          </div>


				<div class="send-container" v-if="ei.state == 'export'">
					<form v-on:submit.prevent="onExport">
	     				

	     				<div class="alert alert-success text-center" v-if="successExport">
	     					<h2>File Secret Key: <span> {{ exportFile.secretKey }} </span></h2>

	     					<h5>IMPORTANT: MUST READ!</h5>

	     					<p>The File Secret Keys provided are dynamically assigned. </p>
	     					<p>Please save immediately and securely.  </p>
	     					<h5>DO NOT LOSE!</h5>

	     					<button class="btn btn-primary" v-on:click="doneExport" type="button">Yes, I already save my file secret key</button>
	     				</div>
	     			
	     			<!-- 	<div class="form-container">
		     				<label for="receipt">File Secret Key:</label>
						  <input type="text" id="receipt" name="receipt" v-model="exportFile.secretKey" class="form-control" readonly>
						  <small>*Note: Please keep your file secret key. You will be needing it to import.</small>
						  <span class="error" v-if="errors.secretKey != ''"> {{ errors.secretKey }} </span> 
	     				</div>
	     			 -->
					  
					  <div class="form-container" v-if="!successExport">
						  <label for="ampunt">Amount:</label>
						  <input type="text" id="amount" name="amount" v-model="exportFile.amount" class="form-control">
						  <span class="error" v-if="errors.amount != ''"> {{ errors.amount }} </span> 
					  </div>

					  <div class="form-container" v-if="!successExport">
						<label for="ampunt">Your Private Key:</label>
						<input type="text" id="amount" name="amount" v-model="exportFile.privateKey" class="form-control">
					</div>
					   
					  
					  <div class="flex  margin-top"  v-if="!successExport">
	    				<button  class="btn btn-main" type="submit">  
	    					<span>EXPORT</span>	
	    				</button>
	    				 
	    			</div>
	    			
	    			
					</form>
				</div> 



				<div class="send-container" v-if="ei.state == 'import'">
					<form v-on:submit.prevent="onImport">
	     				
	     			
	     				<div class="form-container">
		     				<label for="receipt">File Secret Key:</label>
						  <input type="text" id="import" name="import" v-model="importFile.secretKey" class="form-control">
						  
						  <span class="error" v-if="errors.importSecretKey != ''"> {{ errors.importSecretKey }} </span> 
	     				</div>

						 <div class="form-container">
							<label for="receipt">Your Private Key:</label>
						 <input type="text" id="import" name="import" v-model="importFile.privateKey" class="form-control">
						 
						</div>
	     			
					  
					  <!-- <div class="form-container">
						  <label for="file">File:</label>
						  <input type="text" id="file" name="file" v-model="importFile.file" class="form-control">
						  <span class="error" v-if="errors.file != ''"> {{ errors.file }} </span> 
					  </div> -->
					   
					   <label for="file">File:</label>
					  <b-form-file
			      v-model="importFile.file"
			      :state="Boolean(importFile.file)"
			      placeholder="Choose a file or drop it here..."
			      drop-placeholder="Drop file here..."
			    ></b-form-file>
			     <span class="error" v-if="errors.file != ''"> {{ errors.file }} </span> 

					  <div class="flex  margin-top">
	    				<button  class="btn btn-main" type="submit">  
	    					<span>IMPORT</span>	
	    				</button>
	    				 
	    			</div>
	    			
	    			
					</form>
				</div> 
		</div>
	</b-modal>
</template>

<script>

import qs from 'querystring' 
import numeral from 'numeral' 
export default {
  name: 'ExportImportFund',
  data: function() {
      return  {
      	ei: {
      		state: 'export'
      	},
      	successExport: false,
      	showError: false,
      	exportFile: {
      		amount: "",
      		secretKey: "",
			privateKey: "",
      	},
      	importFile: {
      		amount: "",
      		file: null,
			  privateKey: "",
      	},
      	errors: {
      		amount: "",
      		secretKey: "",
      		file: "",
      		importSecretKey: ""
      	},
		assetInfo: {}
      }
    },
  	computed: {
    	init() {
        	return this.$store.state.init;
    	},
    	
   	},
   	methods: {
   		doneExport: function(){
   			this.successExport = false;
   			this.errors.amount = ""
			this.errors.secretKey = ""
			this.errors.file = ""
			this.exportFile.amount = ""
			this.exportFile.sec = ""
   		},
   		toggleOption(selected) {
        this.ei.state = selected; 
  
      },

	  getAssetInfo() {
          const options = {
                method: 'POST',
                url: '/cx/smartcontract',
                };
                this.axios(options)
                    .then((response) => {
                      if(response.data.active == true) {
                          this.assetInfo = response.data
                      }
                    })
                    .catch((response) => {
                      console.log(response.response)
                      // if(response.assetKey != "" && response.assetName == "") {
                      //   this.showSteps.one = false
                      //   this.showCheck.one = true
                      //   this.showSteps.two = false
                      //   this.showSteps.three = true
                      //   this.showCheck.two = true
                      //   this.isDisabled.three = false
                      // }
                    });
          },

   		close: function(){
   			this.$bvModal.hide('export-import-modal'); 
			this.errors.amount = "",
			this.errors.secretKey = "",
			this.errors.file = "",
			this.errors.importSecretKey = ""
   		},
   		
formatAmount:  function (num) {

  if(isNaN(num)) {
    num = 0;
  }
  return parseFloat(num).toFixed(8)
},

      generateDigit: function (length) {
        var text = "";
        var possible = "0123456789";

        for (var i = 0; i < length; i++){
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
      },
      downloadURI: function(uri, name) {
          var link = document.createElement("a");
          link.download = name;
          link.href = uri;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          //delete link;
        },

        getFund: function(){
         const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/blx-api/address/' + this.assetInfo.symbol + '/' + this.masterWalletAddress,
          };

          this.axios(options)
            .then((response) => {
               
               this.init.fund = response.data;

              // console.log(this.init.fund.balance);

              this.init.fund.history.sort(function(a, b) {

                return parseFloat(b.input.timeStamp) - parseFloat(a.input.timeStamp);
            });

              this.init.fund.history = this.init.fund.history.slice(0, 10);

              }).catch((err) => {
                  
                console.log(err.response.data)     

                
              })
      },


      onImport: function(){

      	let hasError = false;


      	if(this.importFile.secretKey == '' || this.importFile.secretKey == undefined || this.importFile.secretKey == null) {
					this.errors.importSecretKey = 'Secret key is required.';
					hasError = true;
				}  

				if(this.importFile.file == '' || this.importFile.file == undefined || this.importFile.file == null) {
					this.errors.file = 'File is required.';
					hasError = true;
				}  

				if(!hasError) {
					this.showError = false;

					let formData = new FormData();
      		formData.append('file', this.importFile.file);
      		formData.append('secretKey', this.importFile.secretKey);
      		formData.append('privateKey', this.importFile.privateKey);
			  formData.append('symbol', this.assetInfo.symbol);


					 this.axios.post('/blx-api/fund/import',
									formData, {
			            headers: {
			              'Content-Type': 'multipart/form-data',
			            }
			          })
		            .then((response) => { 
		              this.showError = true;
										this.systemMsg = "Fund imported successfully";
										this.importFile.file = null;
										this.importFile.secretKey = "";
										this.getFund();
						
						
		            }).catch((err) => {
			
			
		                if(err) {
		                  this.showError = true;
		                  this.systemMsg = err.response.data.msgText;
										} 

		            }).finally ( ()=> {
										setTimeout(() => {
											this.showError = false;
											this.systemMsg = '';
											this.errors = { 
												importSecretKey: "",
												file: ""
											}
										},3000)
					
					})
				}

      },

       onExport: function(){

      	let hasError = false;

      	if(this.exportFile.amount == '' || this.exportFile.amount == undefined || this.exportFile.amount == null) {
					this.errors.amount = 'Amount is required.';
					hasError = true;
				} else {
					if(this.exportFile.amount < 0) {
						this.errors.amount = 'Amount is required.';
						hasError = true;
					}
				}

				if(!hasError) {
					this.showError = false;
					  this.exportFile.secretKey = this.generateDigit(3) + "-" + this.generateDigit(4);
					 this.axios.post('/blx-api/fund/export',
						{
							"secretKey": this.exportFile.secretKey,
						    "amount": this.exportFile.amount,
						    "privateKey": this.exportFile.privateKey,
							'symbol': this.assetInfo.symbol
						})
		            .then((response) => { 
		             
		                console.log(response.data);

		                this.downloadURI("data:text/html," + response.data.content, response.data.hash + "." + this.assetInfo.symbol);
										//this.showError = true;
										//this.systemMsg = "Fund exported successfully";
										 
										this.exportFile.amount = '';
										 //this.exportFile.secretKey = this.generateDigit(3) + "-" + this.generateDigit(4);
										 this.successExport = true;
										this.getFund();
						
						
		            }).catch((err) => {
			
			
		                if(err) {
		                  this.showError = true;
		                  this.systemMsg = err.response.data.msgText;
										} 

		            }).finally ( ()=> {
										setTimeout(() => {
											this.showError = false;
											this.systemMsg = '';
											this.errors = { 
												amount: "",
												secretKey: ""
											}
										},3000)
					
					})
				}

      }
   	},
   	mounted(){
   		/* this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
	    
	    })*/
		this.getAssetInfo();
   		
 		},
}
</script>
 
<style scoped lang="scss">
	 
  button {
  	margin-top:1em;
  }

   .error {
  	color: red;
  }

  .email-or-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1em;

      .btn {
        color: #47525b;
        border: none;
        outline: none;
        box-shadow: none;
        border-radius: 0;
        &.active {
          color: #d8962c;
          border-bottom: 1px solid #d8962c;
        }
      }
 
    }
	
</style>
